// File: AboutUs.js
import React from "react";
import "./AboutUs.css";
import labMission from "../images/lab-mission.jpg";
import aiPath from "../images/ai_path.png";
import locationsImage from "../images/locations.png";
import founderImage from "../images/profile_picture.jpeg";
import { useTranslation, Trans } from 'react-i18next';

const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <div className="inside-protforge">
      {/* Lab Mission Section */}
      <div className="lab-mission-section">
        <img src={labMission} alt="Lab Mission" className="lab-mission-image" />
        <div className="lab-mission-overlay">
          <div className="lab-mission-title">{t("about_us.mission_title")}</div>
          <div className="lab-mission-text">
            {t("about_us.mission_text")}
          </div>
        </div>
      </div>

      {/* Mission Text Section */}
      <div className="mission-text-section">
        <h2 className="mission-title">{t("about_us.mission_description_title")}</h2>
        <Trans i18nKey="about_us.mission_description_text" />
      </div>

      {/* How It Is Going Section */}
      <div className="how-it-is-going-section">
        <h2 className="section-title">{t("about_us.how_it_is_going")}</h2>
        <div className="how-it-is-going-content">
          {/* Locations Row */}
          <div className="locations-row">
            <img src={locationsImage} alt="Locations" className="locations-image" />
          </div>

          {/* Achievements Row */}
          <div className="achievements-row">
            <div className="our-achievements">
              <h3>{t("about_us.our_achievements_title")}</h3>
              <div className="achievement-text">
                <h2>{t("about_us.our_achievements_text")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* AI Path Section */}
      <div className="ai-path-section">
        <div className="ai-path-overlay">
          <h2 className="ai-path-title">{t("about_us.ai_path")}</h2>
        </div>
        <img src={aiPath} alt="AI Path" className="ai-path-image" />
      </div>

      {/* Founder Section */}
      <div className="founder-section">
        <div className="founder-quote">
          <p>
            {t("about_us.founder_quote")}
          </p>
          <span className="quote-author">{t("about_us.founder_name")}</span>
        </div>
        <div className="founder-image-container">
          <img src={founderImage} alt="Founder" className="founder-image" />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
