// Home.js
import React from 'react';
import './Home.css';
import labVideo from '../videos/lab-video.mp4';
import professionalImage from '../images/neural-network.jpg';
import serviceIcon1 from '../images/full-antibody-design.jpg';
import serviceIcon2 from '../images/design-antibodies.jpg';
import serviceIcon3 from '../images/collaboration-therapy.jpg';
import timeSavingImage from '../images/neural-network.jpg';
import adaptabilityImage from '../images/neural-network-white.jpg';
import clocksImage from '../images/clocks.jpg';
import generalVideo from '../videos/lab-video.mp4';
import membraneVideo from '../videos/membrane_video.mp4';
import antibodyBindingVideo from '../videos/antibody_binding.mp4';
import vissionImage from '../images/optimistic-future.jpg';
import clientResearcher from '../images/researcher.jpg';
import clientBiotech from '../images/biotech-company.jpg';
import clientPharma from '../images/pharma-company.jpg';
import { useTranslation, Trans } from 'react-i18next';

const Home = () => {
  const { t } = useTranslation();

  return (
    <div className="home-container">
      {/* Video Section */}
      <div className="video-section">
        <video src={labVideo} autoPlay loop muted className="lab-video" />
        <div className="video-text-title">
          <h1>{t("home.title")}</h1>
        </div>
      </div>

      {/* Services Row */}
      <div className="services-row enhanced-box">
        <h2>{t("home.services_title")}</h2>
        <div className="service-options spaced">
          <div className="service">
            <img src={serviceIcon1} alt="Full Development" className="service-icon" />
            <h3>{t("home.services1")}</h3>
          </div>
          <div className="service">
            <img src={serviceIcon2} alt="Design Only" className="service-icon" />
            <h3>{t("home.services2")}</h3>
          </div>
          <div className="service">
            <img src={clientPharma} alt="Collaborative Development" className="service-icon" />
            <h3>{t("home.services3")}</h3>
          </div>
        </div>
      </div>

      {/* Time Saving Row */}
      <div className="row benefit-row highlight-row">
        <div className="image-with-text">
          <img src={clocksImage} alt="Time Saving Left" className="half-image" />
          <div className="image-text scrollable-text">
            <h2>{t("home.advantages_time_title")}</h2>
            <p>
              {t("home.advantages_time_description")}
            </p>
          </div>
        </div>
        <div className="image-with-text">
          <img src={adaptabilityImage} alt="Adaptability Right" className="half-image" />
          <div className="image-text scrollable-text">
            <h2>{t("home.advantages_adaptability_title")}</h2>
            <p>
              {t("home.advantages_adaptability_description")}
            </p>
          </div>
        </div>
      </div>

      {/* Clients Row */}
      <div className="clients-row enhanced-box">
        <h2>{t("home.clients_title")}</h2>
        <div className="client-types spaced">
          <div className="client">
            <img src={clientResearcher} alt="Researchers" className="client-icon" />
            <h3>{t("home.clients1")}</h3>
          </div>
          <div className="client">
            <img src={clientBiotech} alt="Biotech Companies" className="client-icon" />
            <h3>{t("home.clients2")}</h3>
          </div>
          <div className="client">
            <img src={clientPharma} alt="Pharma Companies" className="client-icon" />
            <h3>{t("home.clients3")}</h3>
          </div>
        </div>
      </div>

      {/* General Explanation Row with Video */}
      <div className="row benefit-row video-centered last-video-section">
        <div className="video-text last-video-text">
          <p>
            {t("home.video_text")}
          </p>
        </div>
        <video src={antibodyBindingVideo} autoPlay loop muted className="row-video last-video" />
      </div>

      {/* Vision Section */}
      <div className="row vision-row">
        <div className="vision-text">
          <h2>{t("home.vision_title")}</h2>
          <p>
            <Trans i18nKey="home.vision_description" />
          </p>
        </div>
        <img src={vissionImage} alt="Vision" className="row-vision last-image" />
      </div>
    </div>
  );
};

export default Home; 