// src/pages/OurTools.js
import React from 'react';
import './OurTools.css';
import denovo_steps from '../images/denovo_steps.png';
import backgroundVideo from '../videos/Protforge_automatic.mp4';
import antibodyBinding from '../videos/antibody_binding.mp4';
import { useTranslation, Trans } from 'react-i18next';

const OurTools = () => {
  const { t } = useTranslation();

  return (
    <div className="our-tools">
      {/* Full-width video with overlay text */}
      <div className="video-section">
        <video className="background-video" autoPlay loop muted>
          <source src={backgroundVideo} type="video/mp4" />
          {t("our_tools.video_error")}
        </video>
        <div className="video-text">
          <h1>ProtForge</h1>
          <p>edit the cure.</p>
        </div>
      </div>

      {/* About our AI Section */}
      <div className="about-ai">
        <h2>{t("our_tools.about_ai_title")}</h2>
        <div className="ai-content">
          <div className="ai-column">
            <h3>{t("our_tools.denovo_design_title")}</h3>
            <div className="ai-row ai-box">
              <p className="ai-description">{t("our_tools.denovo_design_description")}</p>
              <img src={denovo_steps} alt="AI Path" className="ai-image" />
            </div>
          </div>
          <div className="ai-column">
            <h3>{t("our_tools.multiparameter_optimization_title")}</h3>
            <div className="ai-row ai-box">
              <p className="ai-description">{t("our_tools.multiparameter_optimization_description")}</p>
              <div className="ai-enumeration">
                <div>{t("our_tools.binding_affinity")}</div>
                <div>{t("our_tools.stability")}</div>
                <div>{t("our_tools.specificity")}</div>
                <div>{t("our_tools.manufacturability")}</div>
                <div>{t("our_tools.toxicity_reduction")}</div>
                <div>{t("our_tools.pharmacokinetics")}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* About our Data Section */}
      <div className="about-data">
        <h2>{t("our_tools.about_data_title")}</h2>
        <div className="data-row">
          <div className="data-column data-box">
            <h3>{t("our_tools.public_data_title")}</h3>
            <p>{t("our_tools.public_data_description")}</p>
          </div>
          <div className="data-column data-box">
            <h3>{t("our_tools.synthetic_data_title")}</h3>
            <p>{t("our_tools.synthetic_data_description")}</p>
          </div>
          <div className="data-column data-box">
            <h3>{t("our_tools.federated_learning_title")}</h3>
            <p>{t("our_tools.federated_learning_description")}</p>
          </div>
        </div>
      </div>

      {/* Playable video section */}
      <div className="playable-video-section">
        <video className="playable-video" controls>
          <source src={backgroundVideo} type="video/mp4" />
          {t("our_tools.video_error")}
        </video>
      </div>

      {/* Final video with overlay text */}
      <div className="final-video-section">
        <video className="final-video" autoPlay loop muted>
          <source src={antibodyBinding} type="video/mp4" />
          {t("our_tools.video_error")}
        </video>
        <div className="final-video-text">
          <h1>{t("our_tools.target_title")}</h1>
          <p>{t("our_tools.target_description")}</p>
        </div>
      </div>
    </div>
  );
};

export default OurTools;
